import fetchAPI, { fetchWebtools } from "@/utils/fetch-strapi";

export const fetchStrapi = (path: string, filters: unknown, webtools = false, sort?: unknown, pagination?: unknown) => {
  const urlParamsObject = {
    populate: "deep,5",
    nested: true,
    filters: filters,
    sort: sort,
    pagination: pagination,
  };
  return webtools ? fetchWebtools(path, urlParamsObject) : fetchAPI(path, urlParamsObject);
};
