import qs from "qs";

interface StrapiUrlParamsInterface {
  pagination?: {
    page?: number;
    pageSize?: number;
    withCount?: boolean;
  };
  [key: string]: unknown;
}

interface StrapiPagedResponse<T> {
  data: T[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

const fetchFromStrapi = async (path: string, urlParamsObject: Record<string, unknown> = {}, options = {}, isWebtools = false) => {
  const IS_SERVER = typeof window === "undefined";
  // set the Strapi URL based on the environment
  let strapiUrl = IS_SERVER ? process.env.STRAPI_URL_INTERNAL : process.env.STRAPI_URL;

  // if we are on the server, but the STRAPI_URL_INTERNAL is not set or is empty, we use the STRAPI_URL
  if (IS_SERVER && (!strapiUrl || strapiUrl === "")) {
    strapiUrl = process.env.STRAPI_URL;
  }

  // merge default and user options
  const mergedOptions = {
    next: { revalidate: 60 },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.STRAPI_API_TOKEN}`,
    },
    ...options,
  };

  // build request URL
  const requestPath = isWebtools ? "/webtools/router" : path;
  const requestUrl = new URL(`/api${requestPath}`, strapiUrl);

  // add query parameters. We need to use "qs" as URLSearchParams doesn't support nested objects
  if (isWebtools) {
    urlParamsObject.path = path;
  }
  requestUrl.search = qs.stringify(urlParamsObject, { addQueryPrefix: true });

  // trigger API call
  const response = await fetch(requestUrl, mergedOptions);
  return response.json();
};

const fetchAPI = (path: string, urlParamsObject: Record<string, unknown> = {}, options = {}) => fetchFromStrapi(path, urlParamsObject, options);
const fetchWebtools = (path: string, urlParamsObject: Record<string, unknown> = {}, options = {}) => fetchFromStrapi(path, urlParamsObject, options, true);

const fetchAPIPaginated = async <T,>(path: string, urlParamsObject: StrapiUrlParamsInterface = {}, options: Record<string, unknown> = {}): Promise<T[]> => {
  const data: T[][] = [];

  urlParamsObject.pagination ??= {};
  urlParamsObject.pagination.page = 1;
  urlParamsObject.pagination.withCount = true;

  do {
    const responseData = (await fetchFromStrapi(path, urlParamsObject, options)) as StrapiPagedResponse<T>;
    data.push(responseData.data);
    urlParamsObject.pagination.page++;

    if (urlParamsObject.pagination.page >= responseData.meta.pagination.pageCount) {
      break;
    }
    // eslint-disable-next-line no-constant-condition
  } while (true);

  return data.flat();
};

export { fetchAPI, fetchAPIPaginated, fetchWebtools };

export default fetchAPI;
