"use client";

import React, { useEffect } from "react";

import { useLayout } from "@/lib/contexts/layout-context";

export const HomeLayout = ({ children }: { children: React.ReactNode }) => {
  const { setShowCategories } = useLayout();

  useEffect(() => setShowCategories(true), [setShowCategories]);

  return <>{children}</>;
};
